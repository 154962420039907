import { Router } from "@reach/router";
import React from "react";

import { LoadingContainer } from "@interactive-investor/onestack-web-shared";

import NewsletterPreferencesContainer from "../../containers/NewsletterPreferences";

import Layout from "../../components/layout";
import { Route } from "../../helpers/routing";

export interface NewsletterPreferencesPageProps {
  location: any;
}

export interface NewsletterPreferencesPageState {
  mounted: boolean;
}

class NewsletterPreferencesPage extends React.Component<
  NewsletterPreferencesPageProps,
  NewsletterPreferencesPageState
> {
  constructor(props: NewsletterPreferencesPageProps) {
    super(props);

    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    // We want this to be a client-only component, so only render something if we are mounted
    if (this.state.mounted) {
      return (
        <Layout>
          <Router>
            <Route
              Component={NewsletterPreferencesContainer}
              path="/newsletter-preferences/:id"
            />
            <Route
              Component={NewsletterPreferencesContainer}
              path="/newsletter-preferences"
            />
          </Router>
        </Layout>
      );
    }

    // If not mounted, render empty
    return <LoadingContainer title="Newsletter Preferences" />;
  }
}

export default NewsletterPreferencesPage;
