import * as React from "react";

import { SEOMeta } from "@interactive-investor/onestack-web-shared";

import { withAuth } from "../../contexts/Auth";
import { AuthContextInjectedProps } from "../../contexts/Auth/interfaces/context";

export interface NewsletterPreferencesProps {
  id?: string;
}
export type MergedProps = NewsletterPreferencesProps & AuthContextInjectedProps;

class NewsletterPreferences extends React.Component<MergedProps> {
  render() {
    const { id } = this.props;

    return (
      <div className="GridRow">
        <SEOMeta
          meta={{
            canonical_url: "",
            description: "",
            robots: "",
            title: "newsletters",
          }}
        />
        <div className="GridCell-xs-12 GridCell-md-8">
          <h1>
            Manage your newsletter subscriptions from interactive investor
          </h1>

          {!id && (
            <p>
              You need to login or follow a link from an ii newsletter email to
              manage your newsletter preferences.
            </p>
          )}

          {id && (
            <p>
              You can manage your newsletter preferences through the links at
              the bottom of the newsletter emails.
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default withAuth<NewsletterPreferencesProps>(NewsletterPreferences);
